<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="row">
            <div class="col-md-6 col-12">
              <mini-statistics-card
                title="DINERO GENERADO"
                :value="formatterMoney(generado)"
                :icon="{
                  component: 'ni ni-money-coins',
                  background: 'bg-gradient-success',
                  shape: 'rounded-circle',
                }"
              />
            </div>

            <div class="col-md-6 col-12">
              <mini-statistics-card
                title="DINERO DISPONIBLE"
                :value="formatterMoney(disponible)"
                :icon="{
                  component: 'ni ni-credit-card',
                  background: 'bg-gradient-warning',
                  shape: 'rounded-circle',
                }"
              />
            </div>

            <div class="col-lg-3 col-md-6 col-12">
              <mini-statistics-card
                title="INVITADOS DIRECTOS"
                :value="directos"
                :icon="{
                  component: 'ni ni-circle-08',
                  background: 'bg-gradient-primary',
                  shape: 'rounded-circle',
                }"
              />
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <mini-statistics-card
                title="INVITADOS INDIRECTOS"
                :value="indirectos"
                :icon="{
                  component: 'ni ni-circle-08',
                  background: 'bg-gradient-warning',
                  shape: 'rounded-circle',
                }"
              />
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <mini-statistics-card
                title="TOTAL INVITADOS"
                :value="total"
                :icon="{
                  component: 'ni ni-circle-08',
                  background: 'bg-gradient-success',
                  shape: 'rounded-circle',
                }"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <tableInvitados :headers="headers" :datos="datos" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/**
 * 
 * :headers="headers"
      :datos="datos"
      @getDataView="getDataView"
 */
import { onBeforeMount, ref } from "vue";
import Swal from "sweetalert2";
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import tableInvitados from "./componentes/tableInvitados.vue";
import InvitadosService from "../services/InvitadosService";

const service = new InvitadosService();

let directos = ref(0);
let indirectos = ref(0);
let total = ref(0);
let generado = ref(0);
let disponible = ref(0);
let datos = ref([]);

let headers = [
  {
    title: "NOMBRE",
    align: "center",
    sortable: false,
    key: "nombre",
  },
  {
    title: "APELLIDO PATERNO",
    key: "apellido",
    sortable: false,
    align: "center",
  },
  {
    title: "APELLIDO MATERNO",
    key: "apellido_mat",
    sortable: false,
    align: "center",
  },
  {
    title: "FECHA REGISTRO",
    key: "fecha_registro",
    sortable: false,
    align: "center",
  },
];

onBeforeMount(() => {
  /*console.log(localStorage.getItem("jwt"));
    console.log(localStorage.getItem("id"));
    console.log(localStorage.getItem("codigo_usuario"));*/
  getAll();
});

const getAll = () => {
  service
    .getData()
    .then((response) => {
      //console.log(response);
      disponible.value = response.data.data.disponible;
      generado.value = response.data.data.generado;
      directos.value = response.data.data.directos;
      indirectos.value = response.data.data.indirectos;
      total.value = response.data.data.total;
      datos.value = response.data.data.invitados;
    })
    .catch((error) => {
      console.log(error);
      msg("Error", error.response.data.message, "error", 3000);
    });
};

const msg = (title, text, icon, timer) => {
  Swal.fire({
    position: "top-end",
    title: title,
    text: text,
    icon: icon,
    showConfirmButton: false,
    timer: timer,
  });
};

const formatterMoney = (value) => {
  const dollar = currencyFormatter({
    currency: "USD",
    value,
  });

  return dollar;
};

function currencyFormatter({ currency, value }) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    minimumFractionDigits: 2,
    currency,
  });
  return formatter.format(value);
}
</script>

<style scoped></style>
