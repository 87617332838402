import axios from "axios";

class CursosService {
  url = "https://apirpm.solopruebas.nl";
  headers = {
    "Content-Type": "multipart/form-data", //"Content-Type": "application/json",
    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
  };

  constructor() {}

  async getData() {
    const res = axios({
      url: this.url + "/api/courses",
      method: "GET",
      //headers: this.headers,
    });

    return res;
  }

  async getId(id) {
    const res = axios({
      url: this.url + "/api/courses/" + id,
      method: "GET",
      headers: this.headers,
    });

    return res;
  }
}

export default CursosService;
