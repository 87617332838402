<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="show" persistent width="1024">
        <v-card>
          <v-card-title>
            <span class="text-h5">Información Detallada</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Fecha Apertura"
                    :model-value="formatDate(props.dataEdit.aperturaFecha)"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Fecha Cierre"
                    :model-value="formatDate(props.dataEdit.cierreFecha)"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Cantidad Solicitada"
                    :model-value="props.dataEdit.cantidad"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Estatus"
                    :model-value="props.dataEdit.status"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Razon"
                    :model-value="props.dataEdit.razonCierre"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="btn me-4 bg-danger"
              color="white"
              variant="text"
              @click="close()"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script setup>
import { computed, onUpdated } from "vue";

//let url = "https://apirpm.solopruebas.nl/resources/";
//let url = "https://cdn.vuetifyjs.com/images/parallax/material.jpg";
const show = computed({
  // getter
  get() {
    return props.banView;
  },
  // setter
  set() {
    // Note: we are using destructuring assignment syntax here.
    //emit("changeView")
    //url = "https://apirpm.solopruebas.nl/resources/";
  },
});

onUpdated(() => {
  /*url += props.dataEdit.imagen;
    console.log("IMAGEN: "+url);*/
});

const props = defineProps(["banView", "dataEdit"]);
const emit = defineEmits(["changeView"]);

const close = () => {
  emit("changeView");
};

const formatDate = (fecha) => {
  //console.log(fecha);

  if (fecha == null) {
    return "";
  }

  var opciones = { year: "numeric", month: "short", day: "numeric" };
  var date = new Date(fecha)
    .toLocaleDateString("es", opciones)
    .replace(/ /g, "-")
    .replace(".", "")
    .replace(/-([a-z])/, function (x) {
      return "-" + x[1].toUpperCase();
    });

  return date;
};
</script>

<style scoped></style>
