<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="row">
            <div class="col-md-6 col-12">
              <mini-statistics-card
                title="DINERO GENERADO"
                :value="formatterMoney(generado)"
                :icon="{
                  component: 'ni ni-money-coins',
                  background: 'bg-gradient-success',
                  shape: 'rounded-circle',
                }"
              />
            </div>

            <div class="col-md-6 col-12">
              <mini-statistics-card
                title="DINERO DISPONIBLE"
                :value="formatterMoney(disponible)"
                :icon="{
                  component: 'ni ni-credit-card',
                  background: 'bg-gradient-warning',
                  shape: 'rounded-circle',
                }"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <btnSolicitud
            @changeAdd="changeAdd"
            texto="Nueva Solicitud"
            icono="mdi-sticker-plus"
          />
          <tableSolicitudes
            :headers="headers"
            :datos="datos"
            @getDataView="getDataView"
          />

          <modalNewSoli @add="add" :banAdd="banAdd" @changeAdd="changeAdd" />

          <modalViewSoli
            :banView="banView"
            :dataEdit="dataEdit"
            @changeView="changeView"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import Swal from "sweetalert2";
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import btnSolicitud from "./componentes/btnSolicitud.vue";
import tableSolicitudes from "./componentes/tableSolicitudes.vue";
import modalNewSoli from "./componentes/modalNewSoli.vue";
import modalViewSoli from "./componentes/modalViewSoli.vue";

import SolicitudesService from "../services/SolicitudesService";

const service = new SolicitudesService();

let banAdd = ref(false); //modal
let datos = ref([]);
let disponible = ref(0);
let generado = ref(0);
let dataEdit = ref({});
let banView = ref(false);

let headers = [
  {
    title: "#",
    align: "center",
    sortable: false,
    key: "id",
  },
  {
    title: "FECHA APERTURA",
    key: "aperturaFecha",
    sortable: false,
    align: "center",
  },
  {
    title: "CANTIDAD",
    key: "cantidad",
    sortable: false,
    align: "center",
  },
  {
    title: "FECHA CIERRE",
    key: "cierreFecha",
    sortable: false,
    align: "center",
  },
  {
    title: "ESTATUS",
    key: "status",
    sortable: false,
    align: "center",
  },
  {
    title: "OPCIONES",
    key: "actions",
    sortable: false,
    align: "center",
  },
];

onBeforeMount(() => {
  /*console.log(localStorage.getItem("jwt"));
  console.log(localStorage.getItem("id"));
  console.log(localStorage.getItem("codigo_usuario"));*/
  getAll();
});

const getAll = () => {
  service
    .getData()
    .then((response) => {
      //console.log(response);
      disponible.value = response.data.data.disponible;
      generado.value = response.data.data.generado;
      datos.value = response.data.data.retiros;
    })
    .catch((error) => {
      console.log(error);
      msg("Error", error.response.data.message, "error", 3000);
    });
};

const changeAdd = () => {
  banAdd.value = !banAdd.value;
  //console.log(banAdd.value);
};

const changeView = () => {
  banView.value = !banView.value;
};

const add = (data) => {
  service
    .newSolicitud(data)
    .then((response) => {
      //console.log(response);
      if (response.data.success) {
        msg("EXITO!", "Solicitud Realizada", "success", 2000);
        getAll();
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }
    })
    .catch((error) => {
      console.log(error);
      msg("Error", error.response.data.message, "error", 3000);
    });
};

const getDataView = (idView) => {
  service
    .getId(idView)
    .then((response) => {
      //console.log(response);
      if (response.data.success) {
        dataEdit.value = response.data.data;
        //console.log(response.data.data);
        changeView();
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }
    })
    .catch((error) => {
      console.log(error);
      msg("ERROR!", error.response.data.message, "error", 2000);
    });
};

const msg = (title, text, icon, timer) => {
  Swal.fire({
    position: "top-center",
    title: title,
    text: text,
    icon: icon,
    showConfirmButton: false,
    timer: timer,
  });
};

const formatterMoney = (value) => {
  const dollar = currencyFormatter({
    currency: "USD",
    value,
  });

  return dollar;
};

function currencyFormatter({ currency, value }) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    minimumFractionDigits: 2,
    currency,
  });
  return formatter.format(value);
}
</script>

<style scoped></style>
