<template>
  <div class="btn">
    <v-btn :prepend-icon="props.icono" @click="showModal">
      <template v-slot:prepend>
        <v-icon color="success"></v-icon>
      </template>
      {{ props.texto }}
    </v-btn>
  </div>
</template>

<script setup>
//mdi-comment-edit mdi-sticker-plus
const emit = defineEmits(["changeAdd"]);

const props = defineProps(["texto", "icono"]);

const showModal = () => {
  emit("changeAdd");
};
</script>

<style scoped></style>
