<script setup>
import { onBeforeMount, ref } from "vue";
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";

import Swal from "sweetalert2";
import DashboardService from "../services/DashboardService";
import Carousel from "./componentes/Carousel.vue";

import SolicitudesService from "../services/SolicitudesService";
import btnSolicitud from "./componentes/btnSolicitud.vue";
import modalNewSoli from "./componentes/modalNewSoli.vue";

const service = new DashboardService();
const solicitud = new SolicitudesService();

let cursos = ref([]);
let libros = ref([]);
let noticias = ref([]);

let disponible = ref(0);
let generado = ref(0);
let invitados = ref(0);

let banAdd = ref(false); //modal

onBeforeMount(() => {
  service
    .getData()
    .then(function (response) {
      //console.log(response);
      cursos.value = response.data.data.cursos;
      libros.value = response.data.data.libros;
      noticias.value = response.data.data.noticias;

      disponible.value = response.data.data.disponible;
      generado.value = response.data.data.generado;
      invitados.value = response.data.data.invitados;
    })
    .catch(function (error) {
      Swal.fire({
        position: "top-end",
        title: "SERVICIO NO DISPONIBLE",
        text: error.response.data.message,
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    })
    .then(function () {});
});

const changeAdd = () => {
  banAdd.value = !banAdd.value;
  //console.log(banAdd.value);
};

const add = (data) => {
  solicitud
    .newSolicitud(data)
    .then((response) => {
      //console.log(response);
      if (response.data.success) {
        msg("EXITO!", "Solicitud Realizada", "success", 2000);
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }
    })
    .catch((error) => {
      console.log(error);
      msg("Error", error.response.data.message, "error", 3000);
    });
};

const msg = (title, text, icon, timer) => {
  Swal.fire({
    position: "top-center",
    title: title,
    text: text,
    icon: icon,
    showConfirmButton: false,
    timer: timer,
  });
};

const formatterMoney = (value) => {
  const dollar = currencyFormatter({
    currency: "USD",
    value,
  });

  return dollar;
};

function currencyFormatter({ currency, value }) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    minimumFractionDigits: 2,
    currency,
  });
  return formatter.format(value);
}
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Dinero Disponible"
              :value="formatterMoney(disponible)"
              :icon="{
                component: 'ni ni-money-coins',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Invitados"
              :value="invitados"
              :icon="{
                component: 'ni ni-circle-08',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Dinero Generado"
              :value="formatterMoney(generado)"
              :icon="{
                component: 'ni ni-money-coins',
                background: 'bg-gradient-warning',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <btnSolicitud
              @changeAdd="changeAdd"
              texto="SOLICITAR RETIRO"
              icono="mdi-sticker-plus"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <carousel :datos="noticias" title="NOTICIAS" tipo="noticia" />
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col-lg-12">
            <carousel :datos="cursos" title="CURSOS" tipo="curso" />
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col-lg-12">
            <carousel :datos="libros" title="LIBROS" tipo="libro" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <modalNewSoli @add="add" :banAdd="banAdd" @changeAdd="changeAdd" />
</template>
