<script setup>
import { onBeforeUnmount, onBeforeMount, ref, reactive } from "vue"; //reactive
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router"; //useRouter
import Swal from "sweetalert2";

import AppFooter from "@/examples/PageLayout/Footer.vue";
import RegistroService from "../services/RegistroService";

const body = document.getElementsByTagName("body")[0];

const store = useStore();
const router = useRouter();
const route = useRoute();

onBeforeMount(() => {
  if (route.params.codigo == "" || route.params.codigo == undefined) {
    isCode.value = true;
    //console.log("vacio");
  } else {
    //console.log("no vacio");
    isCode.value = false;
  }

  let sesion = localStorage.getItem("session");
  let token = localStorage.getItem("jwt");
  //console.log(sesion);
  //console.log(token);
  if (token != null && sesion != null) {
    //console.log("No hya topkenm ni sesion");
    router.push("/dashboard");
  }
  //console.log(formData.value.codigoInvitado);

  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add("bg-gray-100");
});

const service = new RegistroService();
const isFormValid = ref(false);
let isCode = ref(true);

const formData = reactive({
  codigoInvitado: "",
  nombre: "",
  apellidoPat: "",
  apellidoMat: "",
  telefono: "",

  direccion: "",
  ciudad: "",
  codigoPostal: "",
  estado: "",
  municipio: "",
  pais: "",

  banco: "",
  clabe: "",
  numeroCuenta: "",
  email: "",
  password: "",
  confirm: "",
});

const textR = [
  (value) => {
    if (value) return true;

    return "Campo Requerido";
  },
];

const telefonoR = [
  (value) => {
    if (value) return true;

    return "Campo Requerido";
  },
  (value) => {
    if (value?.length == 10) return true;

    return "Solo 10 dígitos";
  },
];

const emailR = [
  (value) => {
    if (value) return true;

    return "Campo Requerido.";
  },
  (value) => {
    if (/.+@.+\..+/.test(value)) return true;

    return "Ingresa un correo valido.";
  },
];

const cuentaR = [
  (value) => {
    if (value) return true;
    return "Campo Requerido.";
  },
  /*(value) => {
    if (value?.length == 16) return true;
    return "16 Dígitos Requeridos";
  },*/
];

const clabeR = [
  (value) => {
    if (value) return true;
    return "Campo Requerido.";
  },
  (value) => {
    if (value?.length == 18) return true;
    return "18 Dígitos Requeridos";
  },
];

const passwordR = [
  (value) => {
    if (value) return true;
    return "Campo Requerido.";
  },
  (value) => {
    if (value && value.toLowerCase() !== value) return true;
    return "Al menos una letra mayúscula";
  },
  (value) => {
    if (value && value.toUpperCase() !== value) return true;
    return "Al menos una letra minúscula";
  },
  (value) => {
    if (/\d/.test(value)) return true;
    return "Al menos un  número";
  },
  (value) => {
    if (/[^A-Za-z0-9]/.test(value)) return true;
    return "Al menos un simbolo";
  },
  (value) => {
    if (value && value.length >= 8) return true;
    return "Mínimo 8 carácteres";
  },
];

const submit = async () => {
  console.log("Enviando datos");
  if (!isCode.value) {
    formData.codigoInvitado = route.params.codigo;
    //console.log("entro a la validacion");
  }
  //console.log(formData);
  if (isFormValid.value) {
    service
      .register(formData)
      .then((response) => {
        console.log("--- MENSAJES DE SUCCESS ---");
        //console.log(response);
        //console.log(response.status);
        //console.log(response.data);
        if (response.status == 200 || response.status == 201) {
          msg("EXITO!", "Registro Exitoso", "success", 2000);
          router.push("/");
        }
      })
      .catch((error) => {
        console.log("--- MENSAJES DE ERROR ---");
        console.log(error);
        //console.log(error.request.status);
        //console.log(error.response.data);
        msg("Error", error.response.data.message, "error", 2000);
      });
  } else {
    msg("Error", "Llena los campos solicitados", "warning", 2000);
  }
};

const msg = (title, text, icon, timer) => {
  Swal.fire({
    position: "top-center",
    title: title,
    text: text,
    icon: icon,
    showConfirmButton: false,
    timer: timer,
  });
};
</script>
<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <!--<div class="col-12">
        <navbar isBtn="bg-gradient-light" />
      </div>-->
    </div>
  </div>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
      style="
        background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg&quot;);
        background-position: top;
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h1 class="text-white mb-2 mt-5">BIENVENIDO!</h1>
            <p class="text-lead text-white">Ingresa los siguientes datos.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <p class="text-uppercase text-sm">Información Usuario</p>
              <v-form
                role="form"
                v-model="isFormValid"
                ref="form"
                @submit.prevent="submit()"
              >
                <!--<div class="card-header pb-0">
                  <div class="d-flex align-items-center">
                    <v-btn class="bg-success ms-auto" type="submit">
                      REGISTRARSE
                    </v-btn>
                  </div>
                </div>-->

                <div class="row">
                  <div class="col-md-12" v-show="isCode">
                    <label for="example-text-input" class="form-control-label"
                      >¿TIENES CÓDIGO DE INVITADO?, ¡INGRESALO!</label
                    >
                    <v-text-field
                      type="text"
                      variant="solo"
                      v-model="formData.codigoInvitado"
                    ></v-text-field>
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label"
                      >NOMBRE</label
                    >
                    <v-text-field
                      type="text"
                      variant="solo"
                      v-model="formData.nombre"
                      :rules="textR"
                    ></v-text-field>
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label"
                      >APELLIDO PATERNO</label
                    >
                    <v-text-field
                      type="text"
                      variant="solo"
                      v-model="formData.apellidoPat"
                      :rules="textR"
                    ></v-text-field>
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label"
                      >APELLIDO MATERNO</label
                    >
                    <v-text-field
                      type="text"
                      variant="solo"
                      v-model="formData.apellidoMat"
                      :rules="textR"
                    ></v-text-field>
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label"
                      >TELÉFONO</label
                    >
                    <v-text-field
                      type="number"
                      variant="solo"
                      v-model="formData.telefono"
                      :rules="telefonoR"
                    ></v-text-field>
                  </div>

                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label"
                      >EMAIL</label
                    >
                    <v-text-field
                      type="text"
                      variant="solo"
                      v-model="formData.email"
                      :rules="emailR"
                    ></v-text-field>
                  </div>
                </div>

                <hr class="horizontal dark" />
                <p class="text-uppercase text-sm">Información de Contacto</p>
                <div class="row">
                  <div class="col-md-12">
                    <label for="example-text-input" class="form-control-label"
                      >Dirección</label
                    >
                    <v-text-field
                      type="text"
                      variant="solo"
                      v-model="formData.direccion"
                      :rules="textR"
                    ></v-text-field>
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label"
                      >Ciudad</label
                    >
                    <v-text-field
                      type="text"
                      variant="solo"
                      v-model="formData.ciudad"
                      :rules="textR"
                    ></v-text-field>
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label"
                      >País</label
                    >
                    <v-text-field
                      type="text"
                      variant="solo"
                      v-model="formData.pais"
                      :rules="textR"
                    ></v-text-field>
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label"
                      >Estado</label
                    >
                    <v-text-field
                      type="text"
                      variant="solo"
                      v-model="formData.estado"
                      :rules="textR"
                    ></v-text-field>
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label"
                      >Postal code</label
                    >
                    <v-text-field
                      type="number"
                      variant="solo"
                      v-model="formData.codigoPostal"
                      :rules="textR"
                    ></v-text-field>
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label"
                      >Municipio</label
                    >
                    <v-text-field
                      type="text"
                      variant="solo"
                      v-model="formData.municipio"
                      :rules="textR"
                    ></v-text-field>
                  </div>
                </div>

                <hr class="horizontal dark" />
                <p class="text-uppercase text-sm">Datos Bancarios</p>
                <div class="row">
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label"
                      >Banco</label
                    >
                    <v-text-field
                      type="text"
                      variant="solo"
                      v-model="formData.banco"
                      :rules="textR"
                    ></v-text-field>
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label"
                      >Número de Cuenta</label
                    >
                    <v-text-field
                      type="number"
                      variant="solo"
                      v-model="formData.numeroCuenta"
                      :rules="cuentaR"
                    ></v-text-field>
                  </div>

                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label"
                      >CLABE</label
                    >
                    <v-text-field
                      type="number"
                      variant="solo"
                      v-model="formData.clabe"
                      :rules="clabeR"
                    ></v-text-field>
                  </div>
                </div>

                <hr class="horizontal dark" />
                <p class="text-uppercase text-sm">Generar Contraseña</p>
                <div class="row">
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label"
                      >CONTRASEÑA</label
                    >
                    <v-text-field
                      type="password"
                      variant="solo"
                      v-model="formData.password"
                      :rules="passwordR"
                    ></v-text-field>
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label"
                      >CONFIRMAR CONTRASEÑA</label
                    >
                    <v-text-field
                      type="password"
                      variant="solo"
                      v-model="formData.confirm"
                      :rules="[
                        formData.confirm === formData.password ||
                          'Las contraseñas no coinciden',
                      ]"
                    ></v-text-field>
                  </div>
                </div>
                
                <hr>
                <div class="row">
                  <div class="col-md-12">
                    <div class="centro">
                      <v-btn class="bg-success ms-auto" type="submit">
                        REGISTRARSE
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<style scoped></style>
