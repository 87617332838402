<template>
  <div>
    <!--<v-btn @click="dialog = true" class="btn" prepend-icon="mdi-sticker-plus">
        <template v-slot:prepend>
          <v-icon color="success"></v-icon>
        </template>
        Nuevo Registro
      </v-btn>-->

    <v-dialog v-model="show" max-width="600">
      <v-card prepend-icon="mdi-sticker-plus" title="Nueva Solicitud de Retiro">
        <form @submit.prevent="submit">
          <v-text-field
            v-model="descripcion.value.value"
            :error-messages="descripcion.errorMessage.value"
            label="Descripción"
          ></v-text-field>

          <v-text-field
            v-model="cantidad.value.value"
            :counter="10"
            :error-messages="cantidad.errorMessage.value"
            label="Cantidad"
            type="number"
          ></v-text-field>

          <v-btn class="me-4 bg-success" type="submit"> Solicitar </v-btn>
          <v-btn
            class="ms-auto bg-red"
            text="Cancelar"
            @click="close()"
          ></v-btn>
          <!--<v-btn class="me-4" type="submit"> submit </v-btn>
            <v-btn @click="handleReset"> clear </v-btn>-->
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { onMounted, computed } from "vue";
import { useField, useForm } from "vee-validate";

const show = computed({
  // getter
  get() {
    return props.banAdd;
  },
  // setter
  set() {
    // Note: we are using destructuring assignment syntax here.
    handleReset();
    //emit("changeDialog")
  },
});

//handleSubmit, handleReset
const { handleSubmit, handleReset } = useForm({
  validationSchema: {
    cantidad(value) {
      if (value) return true;
      return "Campo Requerido.";
    },
    descripcion(value) {
      if (value) return true;
      return "Campo Requerido.";
    },
  },
});

const descripcion = useField("descripcion");
const cantidad = useField("cantidad");

const submit = handleSubmit((values) => {
  let row = {
    descripcion: values.descripcion,
    cantidad: values.cantidad,
  };

  show.value = false;

  emit("add", row);
  emit("changeAdd");
});

const close = () => {
  handleReset();
  emit("changeAdd");
};

onMounted(() => {});

/* EMITS */
const emit = defineEmits(["add", "changeAdd"]);

/* PROPS */
const props = defineProps(["banAdd"]);
</script>

<style scoped>
form {
  padding: 10px 10px;
}
</style>
