<script setup>
import { onBeforeMount, onMounted, onBeforeUnmount, ref } from "vue";
import { useStore } from "vuex";

import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
//import ProfileCard from "./components/ProfileCard.vue";
//import ArgonInput from "@/components/ArgonInput.vue";
//mport ArgonButton from "@/components/ArgonButton.vue";
import Swal from "sweetalert2";

import AuthService from "../services/AuthService";

const service = new AuthService();

const body = document.getElementsByTagName("body")[0];

const store = useStore();

let pass = ref("");
let newPass = ref("");
let confirm = ref("");

const form = ref(null);

const passwordR = [
  (value) => {
    if (value) return true;
    return "Campo Requerido.";
  },
  (value) => {
    if (value && value.toLowerCase() !== value) return true;
    return "Al menos una letra mayúscula";
  },
  (value) => {
    if (value && value.toUpperCase() !== value) return true;
    return "Al menos una letra minúscula";
  },
  (value) => {
    if (/\d/.test(value)) return true;
    return "Al menos un  número";
  },
  (value) => {
    if (/[^A-Za-z0-9]/.test(value)) return true;
    return "Al menos un simbolo";
  },
  (value) => {
    if (value && value.length >= 8) return true;
    return "Mínimo 8 carácteres";
  },
];

const cambiarPass = () => {
  let datos = {
    pwd: newPass.value,
    prevPwd: pass.value,
  };
  service
    .changePwd(datos)
    .then(function (response) {
      console.log(response);
      if (response.data.success) {
        form.value.reset();
        Swal.fire({
          position: "top-end",
          title: "CONTRASEÑA ACTUALIZADA!",
          text: response.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          position: "top-end",
          title: "ERROR!",
          text: response.data.message,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    })
    .catch(function (error) {
      console.log(error);
      Swal.fire({
        position: "top-end",
        title: "ERROR!",
        text: error.response.data.message,
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    })
    .then(function () {});
};

onMounted(() => {
  store.state.isAbsolute = true;
  setNavPills();
  setTooltip();
});

onBeforeMount(() => {
  store.state.imageLayout = "profile-overview";
  store.state.showNavbar = false;
  store.state.showFooter = true;
  store.state.hideConfigButton = true;
  body.classList.add("profile-overview");
});
onBeforeUnmount(() => {
  store.state.isAbsolute = false;
  store.state.imageLayout = "default";
  store.state.showNavbar = true;
  store.state.showFooter = true;
  store.state.hideConfigButton = false;
  body.classList.remove("profile-overview");
});
</script>

<template>
  <main>
   
    <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <!--<div class="col-12">
        <navbar isBtn="bg-gradient-light" />
      </div>-->
    </div>
  </div>
  <main class="main-content mt-0">
    <div class="container" style="padding-top: 90px;">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>Cambiar Contraseña!</h5>
            </div>
            <div class="card-body">
              <v-form
                role="form"
                @submit.prevent="cambiarPass()"
                v-model="isFormValid"
                ref="form"
              >
               
                <v-text-field
                  label="Contraseña"
                  variant="solo"
                  v-model="pass"
                ></v-text-field>

                <v-text-field
                  label="Nueva Contraseña"
                  variant="solo"
                  v-model="newPass"
                  :rules="passwordR"
                ></v-text-field>

                <v-text-field
                  label="Confirmar Contraseña"
                  variant="solo"
                  v-model="confirm"
                  :rules="[
                    confirm === newPass ||
                      'Las contraseñas no coinciden',
                  ]"
                ></v-text-field>

                <div class="text-center">
                  <v-btn
                    class="btn me-4 bg-primary"
                    text="Cambiar!"
                    type="submit"
                  ></v-btn>
                </div>
              </v-form>
            </div>

            <div></div>
          </div>
        </div>
      </div>
    </div>
  </main>
  </main>
</template>
