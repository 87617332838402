<script setup>
import { onBeforeUnmount, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";

import { useRouter } from "vue-router";
import Swal from "sweetalert2";

//import Navbar from "@/examples/PageLayout/Navbar.vue";
//import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonInput from "@/components/ArgonInput.vue";
//import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import AuthService from "../services/AuthService";
const body = document.getElementsByTagName("body")[0];

const router = useRouter();
const store = useStore();

onBeforeMount(() => {
  let sesion = localStorage.getItem("session");
  let token = localStorage.getItem("jwt");
  /*console.log(sesion);
  console.log(token);*/
  if (token != null && sesion != null) {
    //console.log("No hya topkenm ni sesion");
    router.push("/dashboard");
  }

  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add("bg-gray-100");
});

const service = new AuthService();
let email = ref("");
let pass = ref("");
let _token = ref("");
let isActive = ref(false);

const submit = () => {
  //console.log("Entrando al submit perros");
  service
    .signin({
      email: email.value,
      password: pass.value,
    })
    .then(function (response) {
      /*console.log(response.status);
      console.log(response);*/
      if (response.status == 220) {
        //tiene activado el 2FA
        msg(
          "INGRESA EL CODIGO QUE SE HA ENVIADO A TU CORREO",
          "",
          "success",
          3000
        );
        isActive.value = !isActive.value;
      } else if (response.status == 200) {
        //no tiene el login 2FA
        msg("BIENVENIDO", "", "success", 2000);
        localStorage.setItem("id", response.data.data.id);
        localStorage.setItem(
          "codigo_usuario",
          response.data.data.codigo_usuario
        );
        localStorage.setItem("nombre", response.data.data.nombre);
        localStorage.setItem("apellido", response.data.data.apellido);
        localStorage.setItem("apellido_mat", response.data.data.apellido_mat);
        localStorage.setItem("pagado", response.data.data.pagado);

        localStorage.setItem("banco", response.data.data.banco);
        localStorage.setItem("clabe", response.data.data.clabe);
        localStorage.setItem("cuenta", response.data.data.cuenta);

        localStorage.setItem("telefono", response.data.data.telefono);
        localStorage.setItem("email", response.data.data.email);

        localStorage.setItem("direccion", response.data.data.direccion);
        localStorage.setItem("estado", response.data.data.estado);
        localStorage.setItem("ciudad", response.data.data.ciudad);
        localStorage.setItem("cp", response.data.data.cp);
        localStorage.setItem("pais", response.data.data.pais);
        localStorage.setItem("municipio", response.data.data.municipio);

        localStorage.setItem("session", true);
        localStorage.setItem("jwt", response.data.data.token);
        router.push("/dashboard");
      } else {
        msg(response.data.message, "", "error", 2000);
      }
    })
    .catch(function (error) {
      console.log(error);
      msg(error.response.data.message, "", "error", 2000);
    })
    .then(function () {});
};

const login2FA = () => {
  service
    .log2FA({ email: email.value, token: _token.value })
    .then((response) => {
      //console.log(response);
      if (response.data.success) {
        msg("BIENVENIDO", "", "success", 2000);
        localStorage.setItem("id", response.data.data.id);
        localStorage.setItem(
          "codigo_usuario",
          response.data.data.codigo_usuario
        );
        localStorage.setItem("nombre", response.data.data.nombre);
        localStorage.setItem("apellido", response.data.data.apellido);
        localStorage.setItem("apellido_mat", response.data.data.apellido_mat);
        localStorage.setItem("pagado", response.data.data.pagado);

        localStorage.setItem("banco", response.data.data.banco);
        localStorage.setItem("clabe", response.data.data.clabe);
        localStorage.setItem("cuenta", response.data.data.cuenta);

        localStorage.setItem("telefono", response.data.data.telefono);
        localStorage.setItem("email", response.data.data.email);

        localStorage.setItem("direccion", response.data.data.direccion);
        localStorage.setItem("estado", response.data.data.estado);
        localStorage.setItem("ciudad", response.data.data.ciudad);
        localStorage.setItem("cp", response.data.data.cp);
        localStorage.setItem("pais", response.data.data.pais);
        localStorage.setItem("municipio", response.data.data.municipio);

        localStorage.setItem("session", true);
        localStorage.setItem("jwt", response.data.data.token);
        router.push("/dashboard");
      }else{
        msg("SERVICIO NO DISPONIBLE", response.data.message, "success", 2000);
      }
    })
    .catch((error) => {
      console.log("--- MENSAJES DE ERROR ---");
      /*console.log(error);
        console.log(error.request.status);
        console.log(error.response.data);*/
      msg("Error", error.response.data.message, "error", 2000);
    });
};

const msg = (title, text, icon, timer) => {
  Swal.fire({
    position: "top-center",
    title: title,
    text: text,
    icon: icon,
    showConfirmButton: false,
    timer: timer,
  });
};
</script>
<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <!--<div class="col-12">
        <navbar isBtn="bg-gradient-light" />
      </div>-->
    </div>
  </div>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
      style="
        background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg&quot;);
        background-position: top;
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h1 class="text-white mb-2 mt-5">BIENVENIDO!</h1>
            <p class="text-lead text-white"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>INICIAR SESIÓN</h5>
            </div>
            <div class="card-body">
              <v-form
                role="form"
                @submit.prevent="isActive == true ? login2FA() : submit()"
              >
                <argon-input
                  id="email"
                  type="email"
                  placeholder="Email"
                  aria-label="Email"
                  v-model="email"
                />
                <argon-input
                  v-show="!isActive"
                  id="password"
                  type="password"
                  placeholder="Password"
                  aria-label="Password"
                  v-model="pass"
                />
                <argon-input
                  v-show="isActive"
                  id="token"
                  type="token"
                  placeholder="Ingresa el Código"
                  aria-label="token"
                  v-model="_token"
                />
                <div class="text-center">
                  <argon-button
                    fullWidth
                    color="dark"
                    variant="gradient"
                    class="my-4 mb-2"
                    type="submit"
                    >Ingresar...</argon-button
                  >
                </div>
                <p class="text-sm mt-3 mb-0 text-center">
                  ¿No tienes una cuenta?
                  <RouterLink
                    to="/registro"
                    class="text-dark font-weight-bolder"
                    >Registrate!</RouterLink
                  >
                </p>
                <p class="text-sm mt-3 mb-0 text-center">
                  ¿Olvidaste tu contraseña?
                  <RouterLink
                    to="/forgot-password"
                    class="text-dark font-weight-bolder"
                    >Click Aqui!</RouterLink
                  >
                </p>
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <!--<app-footer />-->
</template>

<style scoped></style>
