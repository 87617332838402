<template>
  <div class="card mi-card">
    <div class="card-header pb-0">
      <div class="d-flex align-items-center">
        <h1 class="card-title">{{ props.title }}</h1>
      </div>
    </div>
    <div class="card-body">
      <v-carousel
        height="600"
        show-arrows="hover"
        cycle
        hide-delimiter-background
      >
        <v-carousel-item v-for="(slide, i) in props.datos" :key="i">
          <v-sheet :color="colors[i]" height="100%">
            <div class="d-flex fill-height justify-center align-center">
              <div
                class="col-12 componentCardClk"
                style="padding-bottom: 20px"
                @click="ver(slide.id)"
              >
                <v-card class="mx-auto" max-width="">
                  <v-img
                    height="500px"
                    :src="`https://apirpm.solopruebas.nl/resources/${slide.imagen}`"
                    cover
                  ></v-img>

                  <v-card-title> {{ slide.titulo }} </v-card-title>
                  <p class="cardSubtitle">{{ slide.descripcion }}</p>
                </v-card>
              </div>
              
            </div>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

let colors = [
  "indigo",
  "warning",
  "pink darken-2",
  "red lighten-1",
  "deep-purple accent-4",
];

//let slides = ["First", "Second", "Third", "Fourth", "Fifth"];

const props = defineProps(["datos","title","tipo"]);

const ver = (id) => {
  switch (props.tipo) {
    case "curso":
      router.push("/curso/" + id);
      break;
    case "libro":
      router.push("/libro/" + id);
      break;
    case "noticia":
      router.push("/noticia/" + id);
      break;
  }
};
</script>

<style scoped></style>
