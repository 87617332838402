import axios from "axios";

class LibrosService {
  url = "https://apirpm.solopruebas.nl";
  headers = {
    "Content-Type": "multipart/form-data",
    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
  };

  constructor() {}

  async getData() {
    const res = axios({
      url: this.url + "/api/books",
      method: "GET",
    });

    return res;
  }

  async getId(id) {
    const res = axios({
      url: this.url + "/api/books/" + id,
      method: "GET",
      headers: this.headers,
    });

    return res;
  }
}

export default LibrosService;
