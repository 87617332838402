<script setup>
import CardComponent from "./componentes/CardComponent.vue";
import CursosService from "../services/CursosService";
import { onBeforeMount, ref } from "vue";

let datos = ref([]);
const service = new CursosService();

onBeforeMount(() => {
  service
    .getData()
    .then(function (response) {
      //console.log(response.data.data);
      datos.value = response.data.data;
      //console.log(datos);
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {});
});
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <!--<CardComponent v-for="dato in datos" :key="dato.id" :id="dato.id" :name="dato.name" 
          :location="dato.location.name" :image="dato.image"/>-->

          <CardComponent v-for="dato in datos" :key="dato.id" :id="dato.id" :titulo="dato.titulo" 
          :descripcion="dato.descripcion" :cuerpo="dato.cuerpo" :imagen="dato.imagen" TextoBtn="Ver Curso" tipo="curso"/>

        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
