import axios from "axios";

class CursosService {
  url = "https://apirpm.solopruebas.nl";
  headers = {
    "Content-Type": "application/json", //"Content-Type": "application/json",
    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
  };

  constructor() {}

  async getData() {
    const res = axios({
      url: this.url + "/api/User/Dashboard",
      method: "GET",
      headers: this.headers,
    });

    return res;
  }

}

export default CursosService;
