import axios from "axios";

class RegistroService {
  url = "https://apirpm.solopruebas.nl";
  headers = {
    /*"Access-Control-Allow-Headers":
        "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
      "Access-Control-Allow-Origin": "*",*/
    "Content-Type": "application/json", //"Content-Type": "application/json", "Content-Type": "multipart/form-data
  };

  constructor() {}

  async register(datos) {
    const res = axios({
      url: this.url + "/api/User/register",
      method: "POST",
      headers:this.headers,
      data: JSON.stringify(datos),
    });

    return res;
  }


}

export default RegistroService;
