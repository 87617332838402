<template>
  <div>
    <v-card title="Búsqueda" flat>
      <template v-slot:text>
        <v-text-field
          v-model="search"
          label="Search"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          hide-details
          single-line
        ></v-text-field>
      </template>

      <v-data-table
        class="elevation-1 mi-tabla"
        :headers="props.headers"
        :items="props.datos"
        item-key="id"
        items-per-page="5"
        :search="search"
      >
        <template v-slot:no-data>
          <v-alert :value="true" color="warning" type="warning">
            Lo siento, no hay información disponible . . .
          </v-alert>
        </template>
        
        <template #[`item.fecha_registro`]="{ item }">
          <p>{{ formatDate(item.fecha_registro) }}</p>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script setup>
import { ref } from "vue";

let search = ref("");
const props = defineProps(["headers", "datos"]);

const formatDate = (fecha_registro) => {
  var opciones = { year: "numeric", month: "short", day: "numeric" };
  var fecha = new Date(fecha_registro)
    .toLocaleDateString("es", opciones)
    .replace(/ /g, "-")
    .replace(".", "")
    .replace(/-([a-z])/, function (x) {
      return "-" + x[1].toUpperCase();
    });

  return fecha;
};
</script>

<style scoped></style>
