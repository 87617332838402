<template>
  <div class="col-lg-3 col-md-6 col-12 componentCardClk" style="padding-bottom: 20px" @click="detalleLibro">
    <v-card class="mx-auto" max-width="344">
      <v-img
        height="200px"
        :src="`https://apirpm.solopruebas.nl/resources/${imagen}`"
        cover
      ></v-img>

      <v-card-title> {{ props.titulo }} </v-card-title>
      <p class="cardSubtitle"> {{ props.descripcion }} </p>
    </v-card>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { onUpdated, ref } from "vue";
let imagenS = ref("");

const router = useRouter();
const props = defineProps([
  "id",
  "titulo",
  "descripcion",
  "cuerpo",
  "imagen",
  "TextoBtn",
  "tipo",
]);

onUpdated(() => {
  let text = props.imagen;
  const myArray = text.split("/");
  imagenS.value = myArray[myArray.length - 1];
});

const detalleLibro = () => {
  switch (props.tipo) {
    case "curso":
      router.push("/curso/" + props.id);
      break;
    case "libro":
      router.push("/libro/" + props.id);
      break;
    case "noticia":
      router.push("/noticia/" + props.id);
      break;
  }
};
</script>

<style scoped></style>
