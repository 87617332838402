import { createRouter, createWebHistory } from "vue-router";

/*import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Signin from "../views/Signin.vue";*/

import Dashboard from "../views/Dashboard.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";

import RegistroView from "../views/RegistroView.vue";
import SolicitudesView from "../views/SolicitudesView.vue";
import ReporteInvitadosView from "../views/ReporteInvitadosView.vue";
import CursosView from "../views/CursosView.vue";
//import DetalleCursoView from "../views/DetalleCursoView.vue";
import LibrosView from "../views/LibrosView.vue";
//import DetalleLibroView from "../views/DetalleLibroView.vue";
import NoticiasView from "../views/NoticiasView.vue";
//import DetalleNoticiaView from "../views/DetalleNoticiaView.vue";
import PostView from "../views/PostView.vue";
import InvitarView from "../views/InvitarView.vue";

import PageNotFound from "../views/PageNotFound.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import changePassword from "../views/changePassView.vue";
import ResetPassword from "../views/ResetPassView.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/signup",
  },
  /*{
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },

  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },*/

  /* RUTAS USUARIO */
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/registro/:codigo?",
    name: "Registro",
    component: RegistroView,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/reset-password/:token",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      requireAuth: false,
    },
  },
  /* Rutas con Sesion */
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/solicitudes",
    name: "Solicitudes",
    component:SolicitudesView ,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/reporte-invitados",
    name: "Reporte Invitados",
    component:ReporteInvitadosView ,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/invitar",
    name: "Invitar",
    component:InvitarView ,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/cursos",
    name: "Cursos",
    component: CursosView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/curso/:id",
    name: "Curso",
    component: PostView,
    meta: {
      requireAuth: true,
    },
	 props: {module:'course'}
  },
  {
    path: "/noticias",
    name: "Noticias",
    component: NoticiasView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/noticia/:id",
    name: "Noticia",
    component: PostView,
    meta: {
      requireAuth: true,
    },
	 props: {module:'news'}
  },
  {
    path: "/libros",
    name: "Libros",
    component: LibrosView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/libro/:id",
    name: "Libro",
    component: PostView,
    meta: {
      requireAuth: true,
    },
	 props: {module:'book'}
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/changePassword",
    name: "Cambiar Contraseña",
    component: changePassword,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",//ruta no encontrada
    name: "prueba",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const auth = localStorage.getItem("session");
  const needAuth = to.meta.requireAuth;

  if (needAuth && !auth) {
    next("/signup");
  } else {
    next();
  }
});

export default router;
