<template>
  <div>
    <v-card title="Búsqueda" flat>
      <template v-slot:text>
        <v-text-field
          v-model="search"
          label="Search"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          hide-details
          single-line
        ></v-text-field>
      </template>

      <v-data-table
        :headers="props.headers"
        :items="props.datos"
        item-key="id"
        items-per-page="5"
        :search="search"
        class="elevation-1 mi-tabla"
      >

        <template v-slot:no-data>
          <v-alert :value="true" color="warning" type="warning">
            Lo siento, no hay información disponible . . .
          </v-alert>
        </template>

        <template #[`item.aperturaFecha`]="{ item }">
          <p>{{ formatDate(item.aperturaFecha) }}</p>
        </template>

        <template #[`item.cierreFecha`]="{ item }">
          <p>{{ formatDate(item.cierreFecha) }}</p>
        </template>

        <template #[`item.status`]="{ item }">
          <div class="text-center">
            <v-chip
              :color="colorStatus(item.status)"
              variant="flat"
              :text="item.status"
            >
            </v-chip>
          </div>
        </template>

        <template #[`item.actions`]="{ item }">
          <div>
            <v-btn
              density="compact"
              icon="mdi-eye"
              color="green"
              @click="ver(item.id)"
            ></v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script setup>
import { ref } from "vue";

let search = ref("");
const props = defineProps(["headers", "datos"]);
const emit = defineEmits(["getDataEdit", "getIdDelete"]);

const colorStatus = (status) => {
  let color = "";
  switch (status) {
    case "En Revisión":
      color = "yellow-darken-1";
      break;
    case "Pendiente":
      color = "orange";
      break;
    case "En Proceso":
      color = "light-blue-darken-1";
      break;
    case "Aceptada":
      color = "success";
      break;
    case "Rechazada":
    case "Cancelada":
      color = "red-accent-4";
      break;
  }
  return color;
};

const ver = (id) => {
  emit("getDataView", id);
};

const formatDate = (fecha) => {
  //console.log(fecha);

  if (fecha == null) {
    return "";
  }

  var opciones = { year: "numeric", month: "short", day: "numeric" };
  var date = new Date(fecha)
    .toLocaleDateString("es", opciones)
    .replace(/ /g, "-")
    .replace(".", "")
    .replace(/-([a-z])/, function (x) {
      return "-" + x[1].toUpperCase();
    });

  return date;
};
</script>

<style scoped>
</style>
